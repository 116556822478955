import { Reducer } from 'redux'
import { persistReducer } from 'redux-persist'
import createMigrate from 'redux-persist/es/createMigrate'
import storage from 'redux-persist/lib/storage'

import { migrations } from './migrations'

export default (reducers: Reducer) => {
  const persistedReducer = persistReducer(
    {
      key: 'smp',
      version: 1,
      storage,
      whitelist: ['auth', 'user'],
      migrate: createMigrate(migrations, { debug: true }),
    },
    reducers,
  )

  return persistedReducer
}
